exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-howtoreach-js": () => import("./../../../src/pages/howtoreach.js" /* webpackChunkName: "component---src-pages-howtoreach-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-placesnearby-js": () => import("./../../../src/pages/placesnearby.js" /* webpackChunkName: "component---src-pages-placesnearby-js" */),
  "component---src-pages-poojasamagri-js": () => import("./../../../src/pages/poojasamagri.js" /* webpackChunkName: "component---src-pages-poojasamagri-js" */),
  "component---src-pages-poojavidhi-js": () => import("./../../../src/pages/poojavidhi.js" /* webpackChunkName: "component---src-pages-poojavidhi-js" */)
}

